import { createMemo, Show } from 'solid-js';
import { flows, useAuthStore } from './flow';
import type { JSX } from 'solid-js';

type StepProps = {
	header?: () => JSX.Element;
	heading?: string;
	description?: () => JSX.Element;
};

export function StepHeader(props: StepProps) {
	return (
		<Show when={props.header} fallback={<StepHeaderDefault heading={props.heading} description={props.description} />}>
			{
				// NOTE: DO NOT use the argument passed through the `<Show>` children. It will not be updated correctly
				// @ts-expect-error this is funky. despite it being correct, it thinks it is not
				() => {
					const StepHeaderCustom = props.header!;
					return <StepHeaderCustom />;
				}
			}
		</Show>
	);
}

function StepHeaderDefault(props: Omit<StepProps, 'header'>) {
	const [store] = useAuthStore();

	const fallbackDescription = createMemo(() => {
		return typeof flows[store.flow][store.step]?.description !== 'undefined'
			? // @ts-expect-error correctly narrowed above
				flows[store.flow][store.step]!.description()
			: null;
	});

	return (
		<div class="flex flex-col gap-4">
			<Show when={props.heading ?? flows[store.flow][store.step]?.heading}>
				<h2 class="text-3xl font-semibold sm:text-4xl">
					{props.heading ?? flows[store.flow][store.step]?.heading ?? ''}
				</h2>
			</Show>

			<Show when={props.description} fallback={fallbackDescription()}>
				{(description) => {
					const Component = description();
					return <Component />;
				}}
			</Show>
		</div>
	);
}
