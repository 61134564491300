import { useSubmission } from '@solidjs/router';
import { Form, Input, TextField, Label, Button, Errors, TextLink } from '@troon/ui';
import { createEffect, createSignal, onMount } from 'solid-js';
import { useTrackEvent } from '@troon/analytics';
import { TroonRewardsAppStatus, gql, queryAction } from '../../../graphql';
import { useAuthStore } from '../flow';

export function UserExists(props: { nextNewUser: string; nextReturningUser: string }) {
	const [store, setStore] = useAuthStore();
	let input: HTMLInputElement;
	const trackEvent = useTrackEvent();
	const [isValid, setIsValid] = createSignal(/^[^@]+@[^@]+\.[^@]+$/.test(store.data.email ?? ''));

	const res = useSubmission(userExistsAction);

	createEffect(() => {
		if (res.result && !res.result.error) {
			if (res.result?.data?.userExists.status === TroonRewardsAppStatus.NoAccount) {
				trackEvent('signupEnteredEmail', { email: store.data.email });
				setStore({ flow: 'register', step: props.nextNewUser });
			} else {
				setStore({ flow: 'login', step: props.nextReturningUser });
			}
			res.clear();
		}
	});

	onMount(() => {
		if (input && store.data.email) {
			input.value = store.data.email;
		}
	});

	return (
		<Form document={userExistsQuery} action={userExistsAction} method="post" suppressRequired>
			<TextField name="email">
				<Label>Email address</Label>
				<Input
					autofocus
					ref={input!}
					inputMode="email"
					autocomplete="username"
					onInput={(e) => {
						const value = e.currentTarget.value;
						setStore('data', 'email', value);
						setIsValid(/^[^@]+@[^@]+\.[^@]+$/.test(value));
					}}
				/>
			</TextField>

			<div class="hidden" aria-hidden>
				<TextField name="password">
					<Label>Password</Label>
					<Input type="password" onInput={(e) => setStore('data', 'password', e.target.value)} tabindex={-1} />
				</TextField>
			</div>

			<Errors />

			<Button type="submit" disabled={!isValid()}>
				Continue
			</Button>

			<p class="text-center text-sm sm:mx-12">
				By clicking “continue” you are agreeing to Troon’s{' '}
				<TextLink href="https://troon.com/terms-of-use/">Terms of Service</TextLink> and{' '}
				<TextLink href="https://troon.com/privacy-policy/">Privacy Policy</TextLink>.
			</p>
		</Form>
	);
}

const userExistsQuery = gql(`
query userExists($email: String!) {
	userExists(email: $email) {
		status
	}
}
`);

const userExistsAction = queryAction(userExistsQuery, {});
